define("ember-awesome-macros/promise/hash", ["exports", "ember-macro-helpers", "ember-awesome-macros/hash"], function (_exports, _emberMacroHelpers, _hash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _default() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var _deconstructArgs = (0, _hash.deconstructArgs)(args),
        hashKeys = _deconstructArgs.hashKeys,
        hashValues = _deconstructArgs.hashValues;

    return (0, _emberMacroHelpers.curriedComputed)(function () {
      for (var _len2 = arguments.length, newValues = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        newValues[_key2] = arguments[_key2];
      }

      var newHash = (0, _hash.reduceValues)(hashKeys, newValues);
      return Ember.RSVP.hash(newHash);
    }).apply(void 0, _toConsumableArray(hashValues));
  }
});