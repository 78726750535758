define("ember-highcharts/templates/components/high-charts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s16uDXw6",
    "block": "[[[10,0],[14,0,\"chart-container\"],[12],[13],[1,\"\\n\"],[18,1,[[33,1]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"chart\"]]",
    "moduleName": "ember-highcharts/templates/components/high-charts.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});