define("@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-postal-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nPJu8SAg",
    "block": "[[[10,0],[14,\"role\",\"mount-point\"],[12],[13],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0,[\"stripeElement\"]],[30,0,[\"stripeError\"]]]],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-postal-code.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});