define("ember-highcharts/components/high-charts", ["exports", "ember-highcharts/utils/option-loader", "ember-highcharts/utils/chart-data", "ember-highcharts/templates/components/high-charts", "deepmerge"], function (_exports, _optionLoader, _chartData, _highCharts, _deepmerge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* Map ember-highcharts modes to Highcharts methods
   * https://api.highcharts.com/class-reference/Highcharts.html
   */
  var CHART_TYPES = {
    StockChart: 'stockChart',
    Map: 'mapChart',
    undefined: 'chart'
  };

  var _default = Ember.Component.extend({
    layout: _highCharts.default,
    classNames: ['highcharts-wrapper'],
    content: undefined,
    mode: undefined,
    chartOptions: undefined,
    chart: null,
    theme: undefined,
    callback: undefined,
    buildOptions: Ember.computed('chartOptions', 'content.[]', function () {
      var theme = Ember.get(this, 'theme');

      if (theme === undefined) {
        theme = {};
      }

      var passedChartOptions = Ember.get(this, 'chartOptions');

      if (passedChartOptions === undefined) {
        passedChartOptions = {};
      }

      var chartOptions = (0, _deepmerge.default)(theme, passedChartOptions);
      var chartContent = Ember.get(this, 'content'); // if 'no-data-to-display' module has been imported, keep empty series and leave it to highcharts to show no data label.

      if (!Ember.get(this, 'content.length') && !Highcharts.Chart.prototype.showNoData) {
        chartContent = [{
          id: 'noData',
          data: 0,
          color: '#aaaaaa'
        }];
      }

      var defaults = {
        series: chartContent
      };
      return Ember.assign(defaults, chartOptions);
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _Ember$getProperties = Ember.getProperties(this, 'content', 'chart', 'mode'),
          content = _Ember$getProperties.content,
          chart = _Ember$getProperties.chart,
          mode = _Ember$getProperties.mode;

      if (!content || !chart) {
        return;
      }

      var isStockChart = mode === 'StockChart'; // create maps to make series data easier to work with

      var contentSeriesMap = (0, _chartData.getSeriesMap)(content);
      var chartSeriesMap = (0, _chartData.getSeriesMap)(chart.series); // remove and update current series

      var chartSeriesToRemove = [];
      chart.series.forEach(function (series) {
        if (isStockChart && series.name.match(/^Navigator/)) {
          return;
        }

        var contentSeries = contentSeriesMap[series.name];

        if (!contentSeries) {
          return chartSeriesToRemove.push(series);
        }

        var updatedKeys = (0, _chartData.getSeriesChanges)(contentSeries, series); // call series.update() when other series attributes like pointStart have changed

        if (updatedKeys.length) {
          series.update(contentSeries, false);
        } else {
          series.setData(contentSeries.data, false);
        }
      });
      chartSeriesToRemove.forEach(function (series) {
        return series.remove(false);
      }); // add new series

      content.forEach(function (contentSeries) {
        // eslint-disable-next-line no-prototype-builtins
        if (!chartSeriesMap.hasOwnProperty(contentSeries.name)) {
          chart.addSeries(contentSeries, false);
        }
      }); // reset navigator data

      if (isStockChart && chart.xAxis.length) {
        chart.xAxis[0].setExtremes();
      }

      return chart.redraw();
    },
    drawAfterRender: function drawAfterRender() {
      Ember.run.scheduleOnce('afterRender', this, 'draw');
    },
    draw: function draw() {
      var element = this.element && this.element.querySelector('.chart-container');
      var modeAttr = Ember.get(this, 'mode') || undefined;
      var mode = CHART_TYPES[modeAttr];
      var completeChartOptions = [Ember.get(this, 'buildOptions'), Ember.get(this, 'callback')];

      if (element) {
        var _Highcharts;

        var chart = (_Highcharts = Highcharts)[mode].apply(_Highcharts, [element].concat(completeChartOptions));

        Ember.set(this, 'chart', chart);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.drawAfterRender();
      (0, _optionLoader.setDefaultHighChartOptions)(Ember.getOwner(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'chart')) {
        Ember.get(this, 'chart').destroy();
      }
    }
  });

  _exports.default = _default;
});