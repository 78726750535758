define("ember-sortable/templates/components/sortable-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JRuCug9X",
    "block": "[[[18,1,[[28,[37,1],null,[[\"item\",\"model\"],[[50,\"ember-sortable@sortable-item\",0,null,[[\"direction\",\"registerItem\",\"deregisterItem\",\"setSelectedItem\",\"update\",\"prepare\",\"commit\",\"activateKeyDown\",\"deactivateKeyDown\"],[[33,3],[28,[37,4],[[30,0],\"registerItem\"],null],[28,[37,4],[[30,0],\"deregisterItem\"],null],[28,[37,4],[[30,0],\"setSelectedItem\"],null],[28,[37,4],[[30,0],\"update\"],null],[28,[37,4],[[30,0],\"prepare\"],null],[28,[37,4],[[30,0],\"commit\"],null],[28,[37,4],[[30,0],\"activateKeyDown\"],null],[28,[37,4],[[30,0],\"deactivateKeyDown\"],null]]]],[33,5]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"direction\",\"action\",\"model\"]]",
    "moduleName": "ember-sortable/templates/components/sortable-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});