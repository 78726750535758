define("@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-elements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ntbY1es7",
    "block": "[[[18,1,[[28,[37,1],null,[[\"card\",\"cardNumber\",\"cardExpiry\",\"cardCvc\",\"postalCode\"],[[50,\"stripe-card\",0,null,[[\"elements\"],[[30,0,[\"elements\"]]]]],[50,\"stripe-card-number\",0,null,[[\"elements\"],[[30,0,[\"elements\"]]]]],[50,\"stripe-card-expiry\",0,null,[[\"elements\"],[[30,0,[\"elements\"]]]]],[50,\"stripe-card-cvc\",0,null,[[\"elements\"],[[30,0,[\"elements\"]]]]],[50,\"stripe-postal-code\",0,null,[[\"elements\"],[[30,0,[\"elements\"]]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-elements.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});